import React, { useContext } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled, { ThemeContext } from "styled-components"
import { animation } from "../../lib/styled-system/system"

import Link from "../utils/Link"
import Image from "../utils/Image"
import ImageOrVideo from "../atoms/ImageOrVideo"
import { hexToRGBA } from "../../lib/color-helpers"

import Box from "../atoms/Box"
import Button from "../atoms/Button"
import Card from "../atoms/Card"
import ProportionalBox from "../atoms/ProportionalBox"
import { Label, Title } from "../atoms/Typography"
import RichText from "../atoms/RichText"
import Section from "../atoms/Section"
import Flex from "../atoms/Flex"

const TextBox = styled(
  ({
    title,
    label,
    cardTitleVariant,
    cardTitleColor,
    descriptionRichText,
    hasDescription,
    imagePosition,
    imageWidth,
    className,
    textClassName,
    textAlign,
    button,
    showTitle,
    layoutAlign,
    ...rest
  }) => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems={textAlign === "center" ? "center" : "flex-start"}
        justifyContent="flex-start"
        className={className}
        zIndex={10}
        position="relative"
        {...rest}
      >
        {showTitle && (
          <Box
            className={textClassName}
            display="flex"
            alignItems={textAlign === "center" ? "center" : "flex-start"}
            flexDirection="column"
          >
            {label && <Label>{label}</Label>}
            <Title
              textAlign={textAlign === "center" ? "center" : "left"}
              transitionProperty="opacity"
              transitionTimingFunction="ease"
              transitionDuration="md"
              variant={cardTitleVariant === "h2" ? "h2" : "h3"}
              as={cardTitleVariant === "h2" ? "h2" : "h3"}
              color={cardTitleColor}
              mt={label ? 3 : 0}
            >
              {title}
            </Title>
          </Box>
        )}
        <Box
          className={textClassName}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems={textAlign === "center" ? "center" : "flex-start"}
          height="100%"
        >
          {descriptionRichText?.content && (
            <Box mt={showTitle && (label || title) ? 3 : 0}>
              <StyledRichText
                transitionProperty="opacity"
                transitionTimingFunction="ease"
                transitionDuration="md"
                textAlign={textAlign}
                {...descriptionRichText}
              />
            </Box>
          )}
          {button && (
            <Button
              aria-label="Button in image card"
              mt={
                (showTitle && (label || title || hasDescription)) ||
                (!showTitle && hasDescription)
                  ? 4
                  : 0
              }
              {...button}
            />
          )}
        </Box>
      </Box>
    )
  }
)`
  ${animation}
`

const TitleImageBox = ({
  title,
  label,
  cardTitleVariant,
  cardTitleColor,
  descriptionRichText,
  className,
  textClassName,
  textAlign,
  button,
  imageOrVideo,
  imageOrVideoStyle,
  overlayImage,
  imageRatio,
  showText,
  showTitle,
  imageWidth,
  useCard,
  showGradient,
  overlayOpacity,
  reversed,
  layoutAlign,
  height,
  width,
  ...rest
}) => {
  const hasImageOrVideo =
    (imageOrVideo?.isVideoSelected &&
      (imageOrVideo?.videoURL ||
        imageOrVideo?.mobileVideoURL ||
        imageOrVideo?.videoImageFallback)) ||
    imageOrVideo?.desktopImage ||
    imageOrVideo?.mobileImage
  return (
    <Flex
      flexDirection={reversed ? "column-reverse" : "column"}
      alignItems={
        layoutAlign === "center"
          ? "center"
          : layoutAlign === "end"
          ? "flex-end"
          : "flex-start"
      } //align Title on vertical card
      width={width}
      className="imageTextbox"
      {...rest}
    >
      {hasImageOrVideo && (
        <ProportionalBox
          display="flex"
          flex={1.8}
          ratio={imageRatio}
          className="image-container"
          transitionProperty="height, transform"
          transitionTimingFunction="ease"
          transitionDuration="md"
          overflow="initial"
          zIndex={10}
        >
          <Box
            className="image"
            height="100%"
            transitionProperty="height, transform"
            transitionTimingFunction="ease"
            transitionDuration="md"
          >
            <ImageOrVideo
              {...imageOrVideo}
              {...imageOrVideoStyle}
              alternativeText="Image or video in image card"
              videoProps={{ height: "100%" }}
              minHeight={!useCard ? "100%" : "auto"}
              padding={imageWidth === "full" || !useCard ? "0px" : "24px"}
              height="100%"
              width="100%"
            />
          </Box>
          {overlayImage && (
            <Box
              transitionProperty="height, transform"
              transitionTimingFunction="ease"
              transitionDuration="md"
              height="100%"
              className="image"
            >
              <StyledOverlayImage
                {...overlayImage}
                style={{
                  height: "100%",
                  left: 0,
                  top: 0,
                  bottom: 0,
                  position: "absolute",
                  zIndex: 100,
                }}
                imgStyle={{
                  padding: imageWidth === "full" || !useCard ? "0px" : "24px",
                }}
              />
            </Box>
          )}
        </ProportionalBox>
      )}
      {showTitle && (
        <Box
          width="100%"
          className={textClassName}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems={textAlign === "center" ? "center" : "flex-start"}
          textAlign={textAlign === "center" ? "center" : "start"}
          p={useCard === true ? 5 : 0}
          pb={imageWidth === "default" ? 0 : 5}
          flex={0.2}
        >
          {label && <Label>{label}</Label>}
          <Title
            transitionProperty="opacity"
            transitionTimingFunction="ease"
            transitionDuration="md"
            variant={cardTitleVariant === "h2" ? "h2" : "h3"}
            as={cardTitleVariant === "h2" ? "h2" : "h3"}
            color={cardTitleColor}
            mt={label ? 3 : 0}
          >
            {title}
          </Title>
        </Box>
      )}
    </Flex>
  )
}

const StyledLink = styled(Link)`
  &:hover {
    & .image-container {
      //getting scale on image-container or image to avoid issue with image overflowing;
      ${props =>
        props.textBackground === "gradient" &&
        props.imageWidth === "full" &&
        props.overlayImage === null
          ? "transform: scale(1.05)"
          : null};

      & .image {
        ${props =>
          (props.textBackground === "solid" ||
            props.imageWidth === "default") &&
          props.overlayImage === null &&
          (!props.imageOrVideo?.isVideoSelected ||
            props.imageOrVideo?.videoURL === null ||
            props.imageOrVideo?.mobileVideoURL === null ||
            props.imageOrVideo?.videoURL === null)
            ? "transform: scale(1.05)"
            : null};
      }
    }
  }
`

const StyledCard = styled(Card)`
  & .shaved-text {
    opacity: ${props => (props.textVisibility !== "onHover" ? 1 : 0)};
  }
  &:hover {
    & .shaved-text {
      ${props => (props.textVisibility === "onHover" ? "opacity: 1" : null)};
    }
    & .shaved-text {
      opacity: ${props => (props.textVisibility === "onHover" ? 1 : 0.75)};
    }
  }
`

const StyledRichText = styled(RichText)`
  & .RichEditor-unstyled {
    margin-bottom: 0px; // removing unwanted margin from richText
  }
  ${animation}
`

const StyledOverlayImage = styled(Image)`
  opacity: 0;
  transition: opacity 0.3s ease;
  will-change: opacity;
  &:hover {
    opacity: 1;
  }
`

const defaultCard = {
  borderRadius: "none",
  boxShadow: "md",
  bg: 0,
  theme: "light",
  useCard: false,
}

const FlexImageCard = ({
  title,
  label,
  descriptionRichText,
  imageOrVideo,
  imageOrVideoStyle,
  overlayImage,
  link,
  alignText,
  card,
  cardTitle,
  textBackground,
  textVisibility,
  backgroundImageDesktop,
  backgroundImageMobile,
  button,
  overlayOpacity,
  imageWidth,
  imageRatio,
  customImageRatio,
  textLocation,
  imagePosition = "default",
}) => {
  //setting a default value for card prop
  card =
    card && card.constructor === Object && Object.keys(card).length > 0
      ? card
      : defaultCard

  const currentTheme = useContext(ThemeContext)
  const bgColor = card
    ? currentTheme?.themes[card?.theme]?.colors?.background[card?.bg]
    : "#FFFFFF"

  const solidColorWithOpacity = hexToRGBA(bgColor, overlayOpacity * 100) //150)

  let showText = textVisibility !== "none" && bgColor
  let showGradient = textBackground === "gradient" && showText
  const hasDescription =
    descriptionRichText &&
    (descriptionRichText?.content?.blocks?.[0]?.text?.length !== 0 ||
      (descriptionRichText?.content?.internal?.content &&
        JSON.parse(descriptionRichText.content.internal.content)?.blocks?.[0]
          ?.text?.length !== 0))

  const getImageRatio = imageRatio => {
    switch (imageRatio) {
      case "default":
        return 0.75
        break
      case "taller":
        return 1
        break
      case "threeTwo":
        return 0.66
        break
      case "sixteenNine":
        return 0.5625
        break
      case "custom":
        return customImageRatio / 10
      default:
        return 0.75
    }
  }
  const verticalCard = textLocation === "top" || textLocation === "bottom"
  return (
    <StyledLink
      aria-label="Card link"
      optional
      {...link}
      textBackground={textBackground}
      imageWidth={imageWidth}
      overlayImage={overlayImage}
      imageOrVideo={imageOrVideo}
    >
      {card?.useCard === true && (
        <StyledCard
          {...card}
          bg={null} //avoiding card to set bg color
          position="relative"
          textVisibility={textVisibility}
          textBackground={textBackground}
          height="100%"
          width="100%"
        >
          <Section
            backgroundImageMobile={backgroundImageMobile}
            backgroundImageDesktop={backgroundImageDesktop}
            noStandardPadding={true}
            style={{ background: solidColorWithOpacity }} //passing overlay color with opacity to section to get rid of image overflow issue
            overlayOpacity={overlayOpacity}
            height="100%"
            transitionProperty="opacity,background"
            transitionTimingFunction="ease"
            transitionDuration="md"
            display={"contents"}
          >
            <Flex
              height="100%"
              width="100%"
              display="flex"
              flexDirection={
                textLocation === "bottom" ? "column-reverse" : "column"
              }
              className="card-flex"
            >
              {showText && (label || title || hasDescription) && (
                <TextBox
                  showTitle={
                    imagePosition !== "center" || textLocation === "top"
                      ? true
                      : false
                  }
                  layoutAlign={"start"}
                  transitionProperty="opacity"
                  transitionTimingFunction="ease"
                  transitionDuration="md"
                  textAlign={alignText === "center" ? "center" : "left"}
                  descriptionRichText={descriptionRichText}
                  hasDescription={hasDescription}
                  imagePosition={imagePosition}
                  imageWidth={imageWidth}
                  button={button}
                  textClassName="shaved-text"
                  className="text-box"
                  title={title}
                  label={label}
                  {...cardTitle}
                  height="100%"
                  width="100%"
                  pt={
                    imageWidth === "default" && textLocation === "bottom"
                      ? 0
                      : 5
                  }
                  pb={
                    imageWidth === "default" && textLocation === "top" ? 0 : 5
                  }
                  px={5}
                />
              )}
              <TitleImageBox
                showTitle={
                  verticalCard &&
                  imagePosition === "center" &&
                  textLocation !== "top"
                }
                reversed={
                  imagePosition === "center" && verticalCard ? true : false
                }
                showGradient={showGradient}
                overlayOpacity={overlayOpacity}
                showText={showText}
                useCard={card?.useCard}
                imageOrVideo={imageOrVideo}
                imageOrVideoStyle={imageOrVideoStyle}
                overlayImage={overlayImage}
                imageWidth={imageWidth}
                imageRatio={getImageRatio(imageRatio)}
                transitionProperty="opacity"
                transitionTimingFunction="ease"
                transitionDuration="md"
                textAlign={alignText}
                layoutAlign={"start"}
                descriptionRichText={descriptionRichText}
                button={button}
                textClassName="shaved-text"
                className="text-box"
                title={title}
                label={label}
                height="100%"
                width="100%"
                {...cardTitle}
              />
            </Flex>
          </Section>
        </StyledCard>
      )}
      {card?.useCard === false && (
        <Flex
          border={"none"}
          bg={null} //avoiding card to set bg color
          position="relative"
          textVisibility={textVisibility}
          textBackground={textBackground}
          height="100%"
          width="100%"
        >
          <Box height="100%" width="100%">
            <Flex
              height="100%"
              width="100%"
              display="flex"
              flexDirection={
                textLocation === "bottom" ? "column-reverse" : "column"
              }
              className="no-card-flex"
            >
              {showText && (title || hasDescription) && (
                <TextBox
                  showTitle={
                    imagePosition !== "center" || textLocation === "top"
                      ? true
                      : false
                  }
                  layoutAlign={"start"}
                  transitionProperty="opacity"
                  transitionTimingFunction="ease"
                  transitionDuration="md"
                  textAlign={alignText === "center" ? "center" : "left"}
                  descriptionRichText={descriptionRichText}
                  hasDescription={hasDescription}
                  button={button}
                  textClassName="shaved-text"
                  className="text-box"
                  title={title}
                  label={label}
                  {...cardTitle}
                  height="100%"
                  width="100%"
                  pt={textLocation === "bottom" ? 5 : 0}
                  pb={textLocation === "bottom" ? 0 : 5}
                />
              )}
              <TitleImageBox
                showTitle={
                  verticalCard &&
                  imagePosition === "center" &&
                  textLocation !== "top"
                }
                reversed={
                  imagePosition === "center" && verticalCard ? true : false
                }
                // horizontalCard={horizontalCard}
                showGradient={showGradient}
                showText={showText}
                useCard={card?.useCard}
                imageOrVideo={imageOrVideo}
                overlayImage={overlayImage}
                imageWidth={imageWidth}
                imageRatio={getImageRatio(imageRatio)}
                transitionProperty="opacity"
                transitionTimingFunction="ease"
                transitionDuration="md"
                textAlign={alignText}
                layoutAlign={"start"}
                descriptionRichText={descriptionRichText}
                button={button}
                textClassName="shaved-text"
                className="text-box"
                title={title}
                label={label}
                height="100%"
                width="100%"
                {...cardTitle}
              />
            </Flex>
          </Box>
        </Flex>
      )}
    </StyledLink>
  )
}

export default FlexImageCard

FlexImageCard.strapiProps = {
  title: PropTypes.string,
  description: PropTypes.shape(RichText.strapiProps),
}

FlexImageCard.propTypes = {
  ...FlexImageCard.strapiProps,
  cardBackground: PropTypes.oneOf(["none", "0", "1"]),
  cardShadow: PropTypes.bool,
  align: PropTypes.oneOf(["left", "center"]),
  link: PropTypes.shape(Link.strapiProps),
}

export const query = graphql`
  fragment FlexImageCard on STRAPI__COMPONENT_MOLECULES_FLEX_IMAGE_CARD {
    title
    label
    backgroundImageMobile {
      ...ImageWithFullWidthLayout
    }
    backgroundImageDesktop {
      ...ImageWithFullWidthLayout
    }
    descriptionRichText {
      ...RichText
    }
    imageOrVideo {
      ...ImageOrVideo
    }
    overlayImage {
      ...ImageWithConstrainedLayout
    }
    link {
      ...Link
    }
    button {
      ...Button
    }
    tags {
      name
    }
  }
`
